import React from 'react';
import { List, Datagrid, TextField, NumberField, ReferenceField, DateField } from 'react-admin';

import useDefaultFilter from '@/utils/useDefaultFilter';
import { Can, ACCESS, useAuth } from '@/utils/auth';
import { USER, TRANSACTION, MACHINE } from '@/vars';
import { TRANSACTIONS } from '@/vars/resources';
import routes from '@/vars/routes';
import { Page } from '@/components';
import TransactionFilter from '../TransactionFilter';

const MROTransactionList = ({ staticContext, ...props }) => {
  const { permissions } = useAuth();
  const isAdmin = permissions.role === USER.ROLE.ADMIN;

  const filter = {
    ...useDefaultFilter('client'),
    status: TRANSACTION.STATUS.SUCCESS,
    mode: MACHINE.MODE.MRO,
  };

  const listProps = {
    ...props,
    basePath: routes.mroTransactions,
    resource: TRANSACTIONS,
    hasCreate: false,
    hasEdit: false,
    hasShow: false,
    hasList: true,

    filter,
    sort: { field: 'date', order: 'DESC' },
    bulkActionButtons: false,
    perPage: 25,
    filters: <TransactionFilter />,
  };

  return (
    <Can module={ACCESS.MRO_REPORT}>
      <Page sectionTitle="MRO Report" title="List transactions">
        <List {...listProps}>
          <Datagrid>
            <DateField label="Date/Time" source="date" showTime />
            {isAdmin && (
              <ReferenceField label="Company" source="client" reference="clients" link={false}>
                <TextField source="companyName" />
              </ReferenceField>
            )}
            <TextField label="Machine UUID" source="machine.machineUUID" />
            <TextField label="Product name" source="product.name" />
            <TextField label="QTY" source="quantity" />
            <NumberField source="amount" label="Price (Credit)" />
          </Datagrid>
        </List>
      </Page>
    </Can>
  );
};

export default MROTransactionList;
