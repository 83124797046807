import React from 'react';
import { List, Datagrid, TextField, EditButton } from 'react-admin';

import useDefaultFilter from '@/utils/useDefaultFilter';
import { Can, ACCESS, useAuth } from '@/utils/auth';
import { USER } from '@/vars';
import { Page } from '@/components';
import { Filter } from '@/ra';
import StatusField from './StatusField';
import CompanyField from './CompanyField';

const MachineList = props => {
  const { permissions } = useAuth();
  const filter = {
    ...useDefaultFilter('client.id'),
    isDeleted: false,
  };
  const isAdmin = permissions.role === USER.ROLE.ADMIN;
  const searchFilter = <Filter searchKeys={['machineUUID', 'location']} />;

  return (
    <Can module={ACCESS.VENDING_MACHINE}>
      <Page sectionTitle="Vending Machine" title="List machines">
        <List
          {...props}
          filter={filter}
          bulkActionButtons={false}
          sort={{ field: 'createdAt' }}
          filters={searchFilter}
          perPage={25}
        >
          <Datagrid>
            <TextField source="machineUUID" label="UUID" />
            {isAdmin && <CompanyField label="Company" />}
            <StatusField label="Status" />
            <TextField source="location" />
            <TextField source="versionName" />
            <EditButton />
          </Datagrid>
        </List>
      </Page>
    </Can>
  );
};

export default MachineList;
