import React from 'react';
import { Grid, Divider, Avatar, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  SignalCellularAltOutlined as OnlineIcon,
  SignalCellularConnectedNoInternet4BarOutlined as OfflineIcon,
  ReportProblemOutlined as ErrorIcon,
  FeedbackOutlined as OutOfStockIcon,
} from '@material-ui/icons';
import moment from 'moment';

import ContentCard from '@/components/dashboard/ContentCard';

function QuickStatsHeadline({ main = '', sub = '', loading }) {
  const classes = useStyles();
  const fontSize = main.length > 12 ? '1.1rem' : '1.25rem';
  // const fontSize = main.length *s

  return (
    <div className={classes.headlineWrapper}>
      {loading ? (
        <CircularProgress size={30} style={{ marginTop: 5 }} />
      ) : (
        <>
          <div className={classes.headlineMainText} style={{ fontSize }}>
            {main}
          </div>
          <div className={classes.headlineSubText}>{sub}</div>
        </>
      )}
    </div>
  );
}

function QuickVis({ title, icon: VisIcon, value, children, loading }) {
  const classes = useStyles();
  return (
    <div className={classes.quickVisWrapper}>
      <div className={classes.quickVis}>
        {loading ? (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className={classes.quickVisIntro}>
              <div className={classes.quickVisIconWrapper}>{VisIcon}</div>
              <div className={classes.quickVisTitle}>{title}</div>
              <div className={classes.quickVisValue}>{value}</div>
            </div>
            <div className={classes.quickVisContent}>{children}</div>
          </>
        )}
      </div>
    </div>
  );
}

function QuickVisIcon({ color, backgroundColor, icon: VisIconFunc }) {
  const classes = useStyles();
  return (
    <div>
      <Avatar className={classes.quickVisIconAvatar} style={{ backgroundColor, color }}>
        <VisIconFunc className={classes.quickVisIcon} />
      </Avatar>
    </div>
  );
}

function QuickVisContentVendingMachine({ machines }) {
  const machinesLen = machines && machines.length;
  if (!machinesLen) {
    return null;
  }
  return (
    <>
      {machines.slice(0, 3).join(', ')}
      {machinesLen > 3 && ', ... more'}
    </>
  );
}

function QuickStats({
  name,
  email,
  totalSalesAmount,
  totalCreditAmount,
  errorMachines,
  outOfStockMachines,
  onlineMachines,
  offlineMachines,
  profileLoading,
  amountLoading,
  machinesLoading,
  hasAccessSalesReport,
  hasAccessMROReport,
  hasAccessMachine,
}) {
  const classes = useStyles();
  const todayDateString = moment().format('D MMM YYYY');
  const hasAccessReport = hasAccessSalesReport || hasAccessMROReport;

  return (
    <ContentCard>
      <Grid container className={classes.root}>
        <Grid item xs={12} sm={hasAccessReport ? 4 : 12} className={classes.intro}>
          <div className={classes.introSummary}>
            <h4>Welcome to your Dashboard!</h4>
            <QuickStatsHeadline loading={profileLoading} main={name} sub={email} />
          </div>

          {hasAccessReport && (
            <>
              <Divider variant="middle" />
              <div className={classes.introSummary}>
                <h4>Today's Sales</h4>
                <small>{todayDateString}</small>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  {hasAccessSalesReport && (
                    <QuickStatsHeadline loading={amountLoading} main={totalSalesAmount} sub="RM" />
                  )}
                  {hasAccessMROReport && (
                    <QuickStatsHeadline loading={amountLoading} main={totalCreditAmount} sub="Credit" />
                  )}
                </div>
              </div>
            </>
          )}
        </Grid>

        {hasAccessMachine && (
          <Grid item xs={12} sm={8} container className={classes.quickVisItems}>
            <QuickVis
              icon={<QuickVisIcon color="#64d82c" backgroundColor="#d9f7be" icon={OnlineIcon} />}
              title="Online"
              value={onlineMachines.length}
              loading={machinesLoading}
            >
              <QuickVisContentVendingMachine machines={onlineMachines} />
            </QuickVis>
            <QuickVis
              icon={<QuickVisIcon color="#999999" backgroundColor="#e8e8e8" icon={OfflineIcon} />}
              title="Offline"
              value={offlineMachines.length}
              loading={machinesLoading}
            >
              <QuickVisContentVendingMachine machines={offlineMachines} />
            </QuickVis>
            <QuickVis
              icon={<QuickVisIcon color="#ff3a2d" backgroundColor="#ffebe9" icon={ErrorIcon} />}
              title="Error"
              value={errorMachines.length}
              loading={machinesLoading}
            >
              <QuickVisContentVendingMachine machines={errorMachines} />
            </QuickVis>
            <QuickVis
              icon={<QuickVisIcon color="#fa8d17" backgroundColor="#ffe7ba" icon={OutOfStockIcon} />}
              title="Out of Stock"
              value={outOfStockMachines.length}
              loading={machinesLoading}
            >
              <QuickVisContentVendingMachine machines={outOfStockMachines} />
            </QuickVis>
          </Grid>
        )}
      </Grid>
    </ContentCard>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 280,
    '& hr': {
      backgroundColor: theme.palette.primary.alternate,
      opacity: 0.4,
    },
    h4: {
      fontWeight: '800',
    },
  },
  intro: {
    display: 'flex',
    flexDirection: 'column',
  },
  introSummary: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    padding: theme.spacing(1),

    '& h4': {
      fontSize: '1.05rem',
      fontWeight: '400',
      margin: 0,
    },
    '& small': {
      fontSize: '.8rem',
      marginTop: theme.spacing(0.25),
    },
  },
  headlineWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  headlineMainText: {
    fontWeight: 500,
  },
  headlineSubText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  quickVisItems: {
    padding: theme.spacing(1),
  },

  quickVisWrapper: {
    flex: 1,
    minWidth: '50%',
    maxHeight: 150,
    display: 'flex',
    boxSizing: 'border-box',

    '&:nth-child(odd)': {
      paddingRight: theme.spacing(1),
    },
    '&:nth-child(even)': {
      paddingLeft: theme.spacing(1),
    },
    '&:nth-child(1), &:nth-child(2)': {
      paddingBottom: theme.spacing(1),
    },
    '&:nth-child(3), &:nth-child(4)': {
      paddingTop: theme.spacing(1),
    },
  },
  quickVis: {
    border: '1px solid #eaeaea',
    borderRadius: 4,
    boxSizing: 'border-box',
    flex: 1,
    padding: theme.spacing(2),
  },

  quickVisIntro: {
    display: 'flex',
    alignItems: 'center',
  },
  quickVisContent: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
    fontSize: '.8rem',
    textAlign: 'right',
    fontWeight: 200,
  },

  quickVisIconWrapper: {
    marginRight: theme.spacing(1),
  },
  quickVisIconAvatar: {
    width: 46,
    height: 46,
  },
  quickVisIcon: {
    width: 26,
    height: 26,
  },

  quickVisTitle: {
    fontSize: '1.2rem',
    fontWeight: 300,
  },
  quickVisValue: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    textAlign: 'right',
    flex: 1,
  },
}));

export default QuickStats;
