import { useState, useEffect, useCallback } from 'react';
import { db } from '@/vars/firebase';

export const useGetSlotsRealtime = machineId => {
  const [state, setState] = useState({ data: null, loading: true, loaded: false });
  useEffect(() => {
    const unsubscribe = db.collection(`machines/${machineId}/slots`).onSnapshot(querySnapshot => {
      const data = {};
      querySnapshot.forEach(doc => (data[doc.id] = doc.data()));
      setState({ data, loading: false, loaded: true });
    });
    return unsubscribe;
  }, [machineId]);
  return state;
};

export const useGetSlots = machineId => {
  const [state, setState] = useState({ data: null, loading: true, loaded: false });
  const getSlots = useCallback(async () => {
    const snapshots = await db.collection(`machines/${machineId}/slots`).get();
    const data = {};
    snapshots.docs.forEach(doc => (data[doc.id] = doc.data()));
    setState({ data, loading: false, loaded: true });
  }, [machineId]);

  useEffect(() => {
    getSlots();
  }, [getSlots]);

  return state;
};

export const useGetSlot = (machineId, machineSlot) => {
  const [state, setState] = useState({ data: null, loading: true, loaded: false });
  const getSlot = async (machineId, machineSlot) => {
    const doc = await db.doc(`machines/${machineId}/slots/${machineSlot}`).get();
    if (!doc.exists) return false;
    return doc.data();
  };
  useEffect(() => {
    getSlot(machineId, machineSlot)
      .then(data => setState({ data, loading: false, loaded: true }))
      .catch(() => setState({ data: null, loading: false, loaded: true }));
  }, [machineId, machineSlot]);
  return state;
};

export const useSaveSlot = () => {
  const [saving, setSaving] = useState(false);
  const save = async (machineId, data) => {
    setSaving(true);
    await db.doc(`machines/${machineId}/slots/${data.machineSlot}`).set(data, { merge: true });
    setSaving(false);
  };
  return { saving, save };
};

export const useRemoveSlot = (machineId, machineSlot) => {
  const [removing, setRemoving] = useState(false);
  const remove = async () => {
    setRemoving(true);
    await db.doc(`machines/${machineId}/slots/${machineSlot}`).delete();
    setRemoving(false);
  };
  return { removing, remove };
};
