import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const Page = ({ title, sectionTitle, children }) => {
  const classes = useStyle();
  return (
    <div className={classes.root}>
      {sectionTitle && <Typography component="h2" gutterBottom variant="overline" children={sectionTitle} />}
      {title ? (
        <Typography component="h1" variant="h3" children={title} className={classes.title} />
      ) : (
        <Typography component="h1" variant="h3" id="react-admin-title" className={classes.title} />
      )}
      {children}
    </div>
  );
};

const useStyle = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(4),
  },
}));

export default Page;
