import React, { useContext, useState, useEffect } from 'react';
import { useAuthState, useLogout, useGetOne } from 'react-admin';
import { useLocation, Redirect } from 'react-router';
import firebase from 'firebase/app';
import { get, isEmpty } from 'lodash';
import { LinearProgress } from '@material-ui/core';
import { USER } from '@/vars';
import routes from '@/vars/routes';
import { useGetOneQuery } from '@/ra';

export const ACCESS = USER.ACCESS;

const AccessDenied = () => <div>Access Denied</div>;
const Loading = () => <LinearProgress />;

const AuthContext = React.createContext();

export const Auth = ({ children }) => {
  const [state, setState] = useState({ loading: true, loaded: false });
  const { loaded: authLoaded, authenticated } = useAuthState();
  const { pathname } = useLocation();
  const doLogout = useLogout();

  useEffect(() => {
    if (!authLoaded) return;

    // Force token refresh at url path changes
    if (firebase.auth().currentUser) {
      setState(state => ({ ...state, loading: true }));
      firebase
        .auth()
        .currentUser.getIdTokenResult(true)
        .then(token => {
          setState({
            loading: false,
            loaded: true,
            permissions: token.claims,
          });
        })
        .catch(error => {
          doLogout();
          throw error;
        });
    } else {
      setState({
        loaded: true,
        loading: false,
      });
    }
  }, [authLoaded, pathname, doLogout]);

  if (!authLoaded || !state.loaded) {
    return <Loading />;
  }

  if (!authenticated) {
    return <Redirect to={routes.signIn} />;
  }

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

export const checkAccess = (claims, targetAccess) => {
  // @TODO change to constants
  if (claims.role === 'admin') return true;
  const currAccess = get(claims, 'access', []);
  return currAccess.includes(targetAccess);
};

export const Can = ({ module, fallback = <AccessDenied />, children, ...props }) => {
  const { permissions, loaded } = useAuth();
  if (loaded && !checkAccess(permissions, module)) {
    return fallback;
  }
  return React.cloneElement(children, props);
};

export const useClientHas = (client, targetAccess) => {
  const { data: clientData, loaded: clientLoaded } = useGetOne('clients', client);
  const { data: userData, loaded: userLoaded } = useGetOne('users', get(clientData, 'owner', '--x--'));

  if (!clientLoaded || !userLoaded) {
    return false;
  }

  if (isEmpty(userData) || !checkAccess(userData, targetAccess)) {
    return false;
  }

  return true;
};

export const useValidateClientAccess = (clientId, matchAccess) => {
  const [state, setState] = useState({ access: null, loaded: false, loading: true });
  const getOne = useGetOneQuery();

  useEffect(() => {
    (async () => {
      let access = false;
      try {
        if (clientId) {
          const { data: clientData } = await getOne('clients', clientId);
          if (!isEmpty(clientData)) {
            const { data: ownerData } = await getOne('users', clientData.owner);
            access = checkAccess(ownerData, matchAccess);
          }
        }
      } catch (e) {}

      setState({ access, loaded: true, loading: false });
    })();
  }, [clientId, matchAccess, getOne]);

  return state;
};

export const ClientHas = ({ module, fallback = <AccessDenied />, client, children, ...props }) => {
  const hasAccess = useClientHas(client, module);
  if (!hasAccess) {
    return fallback;
  }

  return React.cloneElement(children, props);
};
