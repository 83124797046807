import React from 'react';
import { List, Datagrid, TextField, NumberField, EditButton, DeleteButton, ReferenceField } from 'react-admin';
import { colors } from '@material-ui/core';

import useDefaultFilter from '@/utils/useDefaultFilter';
import { Can, ACCESS, useAuth } from '@/utils/auth';
import { USER } from '@/vars';
import { ActionMenu, Filter } from '@/ra';
import { Page, Label } from '@/components';

const StatusField = ({ record, ...rest }) => {
  const { isActive } = record;
  const text = isActive ? 'active' : 'inactive';
  const color = isActive ? colors.green[500] : colors.grey[600];
  return <Label color={color}>{text}</Label>;
};

const EmployeeList = props => {
  const { permissions } = useAuth();
  const filter = useDefaultFilter('client');
  const isAdmin = permissions.role === USER.ROLE.ADMIN;

  const searchFilter = <Filter searchKeys={['fullName', 'employeeNumber', 'cardNumber', 'email']} />;

  return (
    <Can module={ACCESS.MRO}>
      <Page sectionTitle="MRO" title="List employees">
        <List {...props} filter={filter} sort={{ field: 'createdAt' }} filters={searchFilter} perPage={25}>
          <Datagrid>
            <TextField source="fullName" label="Full name" />
            {isAdmin && (
              <ReferenceField label="Company" source="client" reference="clients" link={false}>
                <TextField source="companyName" />
              </ReferenceField>
            )}
            <TextField source="employeeNumber" label="Employee no." />
            <TextField source="cardNumber" label="Card no." />
            <TextField source="type.name" label="Type" />
            <NumberField source="credit" label="Credit" />
            <StatusField label="Status" />

            <ActionMenu label="Actions">
              <EditButton icon={null} />
              <DeleteButton icon={null} />
            </ActionMenu>
          </Datagrid>
        </List>
      </Page>
    </Can>
  );
};

export default EmployeeList;
