export const USER = {
  ROLE: {
    ADMIN: 'admin',
    CLIENT: 'client',
  },
  ACCESS: {
    VENDING_MACHINE: 'vending_machine',
    INVENTORY: 'inventory',
    REPORT: 'report',
    MRO_REPORT: 'mro_report',
    MRO: 'mro',
    USER: 'user',
  },
  STATUS: {
    ACTIVE: 'active',
    DISABLED: 'disabled',
  },
};

export const MACHINE = {
  STATUS: {
    ONLINE: 'online',
    OUT_OF_STOCK: 'out_of_stock',
    ERROR: 'error',
    OFFLINE: 'offline',
    BILL_ERROR: 'bill_error',
    COIN_ERROR: 'coin_error',
  },
  MODE: {
    COMMERCIAL: 'commercial',
    MRO: 'mro',
  },
  PAYMENT_METHOD: {
    IPAY88: 'iPay88',
    CASH_COIN: 'cashCoin',
  },
};

export const IPAY88_DEFAULT = {
  merchantCode: 'M15137',
  merchantKey: 'Vx7AbhyzGK',
  phoneNumber: '042958664',
  email: 'support@vechnology.com.my',
  username: 'vechnology',
  isActive: true,
};

export const VENDING_MACHINE_MODELS = ['VE-E200C', 'VE-E200N'];

export const TRANSACTION = {
  STATUS: {
    SUCCESS: 1,
    FAIL: 0,
  },
  GATEWAY: {
    '234': 'Alipay Scan',
    '305': 'WeChatPay CN Scan',
    '320': 'Boost Scan',
    '329': 'MCashMS',
    '336': 'TNG Scan',
    '338': 'UnionPay Scan',
    '347': 'GrabPay QR',
    '354': 'MaybankQR Scan',
    '379': 'GrabPay Scan',
  },
};

export const SLOT = {
  GRID_COL_NUM: 12,
  GRID_ROW_NUM: 6,
};
