import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { omit, get, isEmpty, merge } from 'lodash';
import { CardActions, CardContent, CardHeader, Grid, Divider, Typography, CircularProgress } from '@material-ui/core';
import { useNotify, required, email } from 'react-admin';
import { useAuth } from '@/utils/auth';
import { USER, IPAY88_DEFAULT } from '@/vars';
import { db } from '@/vars/firebase';
import { Button } from '@/components';
import { TextInput, CheckboxGroupInput, SelectInput } from '@/components/input';
import PaymentMethodForm from './PaymentMethodForm';

const { ROLE, ACCESS } = USER;

const AccessGroupInput = ({ record = {}, disabled }) => {
  const [state, setState] = useState({ loading: true, ownerGrantedAccess: [] });
  const { ownerGrantedAccess, loading } = state;
  const { permissions } = useAuth();
  const loginIsAdmin = permissions.role === ROLE.ADMIN;
  const hasRecord = !isEmpty(record);
  const clientId = hasRecord ? get(record, 'client.id') : permissions.client_id;

  useEffect(() => {
    (async function() {
      // Fetch owner access
      if (!loginIsAdmin) {
        const clientSnapshot = await db
          .collection('clients')
          .doc(clientId)
          .get();

        const ownerId = clientSnapshot.get('owner');
        const ownerSnapshot = await db
          .collection('users')
          .doc(ownerId)
          .get();

        setState({
          loading: false,
          ownerGrantedAccess: ownerSnapshot.get('access'),
        });
      } else {
        setState({
          loading: false,
          ownerGrantedAccess: [],
        });
      }
    })();
  }, [loginIsAdmin, clientId]);

  const all = [
    { value: ACCESS.VENDING_MACHINE, label: 'Vending machine' },
    { value: ACCESS.INVENTORY, label: 'Inventory' },
    { value: ACCESS.REPORT, label: 'Sales Report' },
    { value: ACCESS.USER, label: 'User' },
    { value: ACCESS.MRO, label: 'MRO' },
    { value: ACCESS.MRO_REPORT, label: 'MRO Report' },
  ];

  const options = all.reduce((options, option) => {
    if (!loginIsAdmin && !ownerGrantedAccess.includes(option.value)) {
      return [...options];
    }
    return [...options, option];
  }, []);

  return (
    <>
      <Typography gutterBottom variant="h6">
        Grant access
      </Typography>
      <Typography gutterBottom variant="body2">
        Select to allow user access to modules
      </Typography>
      {loading ? (
        <CircularProgress size={20} />
      ) : (
        <CheckboxGroupInput name="access" options={options} disabled={disabled} />
      )}
    </>
  );
};

const ClientFormContent = ({ saving, values, record, mode, ...props }) => {
  return (
    <>
      <CardHeader title="User details" />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextInput label="First name" source="firstName" validate={required('Please enter a first name.')} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput label="Last name" source="lastName" validate={required('Please enter a last name.')} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              label="Email Address"
              source="email"
              type="email"
              validate={[required('Please enter a valid email.'), email('Please enter a valid email.')]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              label="Password"
              source="password"
              type="password"
              helperText={mode === 'create' ? 'Leave blank to auto generate a password.' : ''}
            />
          </Grid>
          <Grid item xs={12}>
            <AccessGroupInput record={record} disabled={record.isOwner} />
          </Grid>
          <Field component="input" type="hidden" name="role" initialValue={ROLE.CLIENT} />
        </Grid>
      </CardContent>

      {record.isOwner && (
        <>
          <CardHeader title="Company details" />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Company name"
                  source="client.companyName"
                  validate={required('Please enter a company name.')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Company registration no."
                  source="client.companyRegNumber"
                  validate={required('Please enter a company registration no.')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput label="Company address" source="client.companyAddress" />
              </Grid>
            </Grid>
          </CardContent>
        </>
      )}
    </>
  );
};

const AdminFormContent = ({ saving, values, mode, record, ...props }) => {
  return (
    <>
      <CardHeader title="User details" />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextInput label="First name" source="firstName" validate={required('Please enter a first name.')} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput label="Last name" source="lastName" validate={required('Please enter a last name.')} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              label="Email Address"
              source="email"
              type="email"
              validate={[required('Please enter a valid email.'), email('Please enter a valid email.')]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              label="Password"
              source="password"
              type="password"
              helperText={mode === 'create' ? 'Leave blank to auto generate a password.' : ''}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectInput
              label="Role"
              source="role"
              choices={[{ id: '' }, { name: 'Admin', id: ROLE.ADMIN }, { name: 'Client', id: ROLE.CLIENT }]}
              validate={required('Please select a role.')}
              disabled={mode === 'edit'}
            />
          </Grid>
        </Grid>
      </CardContent>

      {values.role === ROLE.CLIENT && (
        <>
          <CardHeader title="Client details" />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Company name"
                  source="client.companyName"
                  validate={required('Please enter a company name.')}
                  disabled={mode === 'edit' && !record.isOwner}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Company registration no."
                  source="client.companyRegNumber"
                  validate={required('Please enter a company registration no.')}
                  disabled={mode === 'edit' && !record.isOwner}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  label="Company address"
                  source="client.companyAddress"
                  disabled={mode === 'edit' && !record.isOwner}
                />
              </Grid>
              <Grid item xs={12}>
                <AccessGroupInput record={record} />
              </Grid>
            </Grid>
          </CardContent>

          <PaymentMethodForm record={record} mode={mode} />
        </>
      )}
    </>
  );
};

const UserForm = ({ record, redirect, save, saving, ...props }) => {
  const { permissions } = useAuth();
  const authRole = permissions.role;
  const notify = useNotify();

  const onSubmit = (data, form, callback) => {
    const cleanData = omit(data, ['createdAt', 'updatedAt']);
    save(cleanData, redirect, {
      onFailure: error => {
        const { details, message } = error;
        if (details) {
          const { code } = details;
          switch (code) {
            case 'auth/invalid-email':
            case 'auth/email-already-exists':
              return callback({ email: message });
            case 'auth/invalid-password':
              return callback({ password: message });
            default:
          }
        }
        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error');
      },
    });
  };

  let initialValues = record;
  if (authRole === ROLE.ADMIN) {
    initialValues = merge(
      {
        client: {
          paymentMethods: {
            ipay88: { ...IPAY88_DEFAULT },
          },
        },
      },
      record
    );
  }

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues} keepDirtyOnReinitialize validateOnBlur>
      {({ handleSubmit, values }) => {
        const formContentProps = {
          ...props,
          values,
          record,
        };

        return (
          <form onSubmit={handleSubmit} noValidate>
            {/* Form content */}
            {authRole === ROLE.ADMIN ? (
              <AdminFormContent {...formContentProps} />
            ) : (
              <ClientFormContent {...formContentProps} />
            )}

            {/* Action bar */}
            <Divider />
            <CardActions>
              <Button type="submit" variant="contained" color="primary" loading={saving}>
                Save
              </Button>
            </CardActions>
          </form>
        );
      }}
    </Form>
  );
};

export default UserForm;
