import React from 'react';
import {
  // TextInput,
  ReferenceInput,
  // NumberInput,
  ImageField,
  ImageInput,
  required,
} from 'react-admin';
import { Form, Field } from 'react-final-form';
import { CardActions, CardContent, CardHeader, Grid, Divider, Typography, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { USER } from '@/vars';
import { useAuth, ClientHas, ACCESS } from '@/utils/auth';
import { price } from '@/utils/validate';
import { Button, BackButton, MROAccessGroupInput } from '@/components';
import { TextInput, SelectInput, NumberInput } from '@/ra';

const useStyles = makeStyles(theme => ({
  productImage: {
    objectFit: 'contain',
    width: '100%',
    height: 280,
    maxHeight: 'none',
  },
}));

const CategoryInput = ({ client }) => {
  return (
    <ReferenceInput
      key={client}
      source="category"
      reference="categories"
      filter={{ client: client || '' }}
      filterToQuery={() => {}}
      perPage={99999}
    >
      <SelectInput optionText="name" validate={required('Please select a category.')} />
    </ReferenceInput>
  );
};

const ProductForm = ({ save, saving, record, redirect, ...rest }) => {
  const classes = useStyles();
  const { permissions } = useAuth();
  const isAdmin = permissions.role === USER.ROLE.ADMIN;
  const onSubmit = data => save(data, redirect);

  return (
    <Form onSubmit={onSubmit} initialValues={record} keepDirtyOnReinitialize validateOnBlur>
      {({ handleSubmit, values, form }) => (
        <form onSubmit={handleSubmit} noValidate>
          <CardHeader title="Product details" />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Typography gutterBottom variant="h6">
                  Product image
                </Typography>
                <ImageInput
                  source="image"
                  accept="image/*"
                  label=""
                  helperText="Recommended size: 720 x 720 px. Display ratio 1:1 png or jpg"
                >
                  <ImageField source="src" classes={{ image: classes.productImage }} />
                </ImageInput>
              </Grid>
              <Grid item xs={12} md={8}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextInput label="Product name" source="name" validate={required('Please enter a name.')} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput source="description" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput source="sku" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput source="serialNumber" />
                  </Grid>
                  {isAdmin ? (
                    <Grid item xs={12}>
                      <ReferenceInput
                        source="client"
                        reference="clients"
                        filterToQuery={() => {}}
                        onChange={() => {
                          const categoryState = form.getFieldState('category');
                          if (categoryState) categoryState.change('');

                          const mroAccessState = form.getFieldState('mroAccessGroup');
                          if (mroAccessState) mroAccessState.change([]);
                        }}
                      >
                        <SelectInput optionText="companyName" validate={required('Please select a client.')} />
                      </ReferenceInput>
                    </Grid>
                  ) : (
                    <Field component="input" type="hidden" name="client" initialValue={permissions.client_id} />
                  )}
                  {values.client && (
                    <Grid item xs={12}>
                      <CategoryInput client={values.client} />
                    </Grid>
                  )}
                  <Grid item xs={12} md={6}>
                    <NumberInput
                      source="priceCash"
                      label="Price (RM)"
                      validate={[
                        required('Please enter a price.'),
                        price('Please enter a valid price with no more than two decimals.'),
                      ]}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">RM</InputAdornment>,
                      }}
                    />
                  </Grid>
                  {values.client && (
                    <ClientHas client={values.client} module={ACCESS.MRO} fallback={null}>
                      <>
                        <Grid item xs={12} md={6}>
                          <NumberInput
                            source="priceCredit"
                            label="Price (Credit)"
                            validate={[
                              required('Please enter a credit price.'),
                              price('Please enter a valid credit price with no more than two decimals.'),
                            ]}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <MROAccessGroupInput client={values.client} />
                        </Grid>
                      </>
                    </ClientHas>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button type="submit" variant="contained" color="primary" loading={saving}>
              Save
            </Button>
            <BackButton />
          </CardActions>
        </form>
      )}
    </Form>
  );
};

export default ProductForm;
