import React from 'react';
import { Box, colors } from '@material-ui/core';
import { isEmpty } from 'lodash';

import { MACHINE } from '@/vars';
import { Label } from '@/components';

const StatusField = ({ record, variant = 'contained' }) => {
  if (isEmpty(record.status)) return null;

  const status = Object.keys(record.status) || [];
  const statusColor = {
    [MACHINE.STATUS.ONLINE]: colors.green[500],
    [MACHINE.STATUS.OFFLINE]: colors.grey[500],
    [MACHINE.STATUS.ERROR]: colors.red[500],
    [MACHINE.STATUS.BILL_ERROR]: colors.red[500],
    [MACHINE.STATUS.COIN_ERROR]: colors.red[500],
    [MACHINE.STATUS.OUT_OF_STOCK]: colors.orange[500],
  };

  return (
    <>
      {status.map((item, index) => (
        <Box display="inline-block" mx={0.5} key={index}>
          <Label color={statusColor[item]} variant={variant}>
            {item}
          </Label>
        </Box>
      ))}
    </>
  );
};

export default StatusField;
