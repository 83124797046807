export const USERS = 'users';
export const PRODUCTS = 'products';
export const CATEGORIES = 'categories';
export const CLIENTS = 'clients';
export const MACHINES = 'machines';
export const TRANSACTIONS = 'transactions';
export const EMPLOYEES = 'employees';
export const EMPLOYEE_TYPES = 'employeeTypes';

// Custom implemented group by query
export const MRO_REPORT_PRODUCTS = 'mro_report_products';
export const MRO_REPORT_EMPLOYEES = 'mro_report_employees';
