import React from 'react';
import { Grid, Card, CardHeader, CardContent, CardActions, Divider } from '@material-ui/core';
import { ReferenceInput, useGetOne, BooleanInput, useRedirect, required } from 'react-admin';
import { Form, Field } from 'react-final-form';
import { get } from 'lodash';

import { Can, ACCESS } from '@/utils/auth';
import { MACHINES } from '@/vars/resources';
import routes from '@/vars/routes';
import { Page, Button, BackButton, DeleteButton } from '@/components';
import { NumberInput, AutocompleteInput } from '@/ra/input';
import ProductForm from './ProductForm';
import { useGetSlot, useSaveSlot, useRemoveSlot } from '../hooks';

const getSlotNum = machineSlot => {
  const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const GRID_COL_NUM = 12;
  return `${ALPHABET[parseInt(machineSlot / GRID_COL_NUM)]}${machineSlot % GRID_COL_NUM}`;
};

const quantityExceedCapacity = msg => (value, values) => {
  const { maxCapacity } = values;
  if (value > maxCapacity) {
    return msg || 'Invalid';
  }
};

const SlotEdit = props => {
  const { match } = props;
  const { machineId, slotNumber } = match.params;
  const { data: machineRecord, loaded: machineLoaded } = useGetOne(MACHINES, machineId);
  const { data: slotRecord, loaded: slotLoaded } = useGetSlot(machineId, slotNumber);
  const { saving, save } = useSaveSlot();
  const { removing, remove } = useRemoveSlot(machineId, slotNumber);
  const redirect = useRedirect();
  const slotLabel = getSlotNum(slotNumber);

  const onSubmit = async data => {
    await save(machineId, data);
    redirect(routes.machines + '/' + machineId);
  };

  const onRemove = async () => {
    await remove();
    redirect(routes.machines + '/' + machineId);
  };

  if (!machineLoaded || !slotLoaded) {
    return null;
  }

  return (
    <Can module={ACCESS.VENDING_MACHINE}>
      <Page sectionTitle="Vending Machine" title={`Edit slot ${slotLabel}`}>
        {/* <Typography gutterBottom>Status: </Typography> */}
        <Card>
          <Form
            onSubmit={onSubmit}
            initialValues={{ dropSensor: true, ...slotRecord }}
            keepDirtyOnReinitialize
            validateOnBlur
          >
            {({ handleSubmit, values, form, dirtyFields, modified }) => {
              return (
                <form onSubmit={handleSubmit} noValidate>
                  <CardHeader title="Slot details" />
                  <CardContent>
                    <Grid container spacing={3}>
                      {/* <Grid item xs={12} md={6}>
                        <NumberInput source="machineSlot" defaultValue={parseInt(slotNumber)} disabled />
                      </Grid> */}
                      <Grid item xs={12} md={6}>
                        <NumberInput
                          label="Item number (display to user)"
                          source="itemNumber"
                          validate={required('Please enter an item number.')}
                        />
                        <Field component="input" type="hidden" name="machineSlot" initialValue={parseInt(slotNumber)} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <NumberInput source="maxCapacity" validate={required('Please enter a max capacity number.')} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <NumberInput
                          label="Total quantity available"
                          source="totalQuantity"
                          validate={[
                            required('Please enter a quantity number.'),
                            quantityExceedCapacity('Quantity cannot exceed the max capacity.'),
                          ]}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <BooleanInput source="dropSensor" />
                        <BooleanInput source="error" label="Slot error" />
                      </Grid>
                    </Grid>
                  </CardContent>

                  <CardHeader title="Select product" />
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <ReferenceInput
                          label="Product"
                          source="product.id"
                          reference="products"
                          filterToQuery={() => {}}
                          filter={{ client: get(machineRecord, 'client.id', '') }}
                          perPage={99999}
                          validate={required('Please select a product.')}
                        >
                          <AutocompleteInput optionText="name" />
                        </ReferenceInput>
                      </Grid>
                    </Grid>
                    {get(values, 'product.id') && (
                      <ProductForm
                        productId={get(values, 'product.id')}
                        formValues={values}
                        dirtyFields={dirtyFields}
                        modified={modified}
                        clientId={get(machineRecord, 'client.id')}
                        machineRecord={machineRecord}
                      />
                    )}
                  </CardContent>
                  <Divider />
                  <CardActions>
                    <Button type="submit" variant="contained" color="primary" loading={saving}>
                      Save
                    </Button>
                    <BackButton />
                    <DeleteButton onClick={onRemove} label="Remove" loading={removing} disabled={saving} />
                  </CardActions>
                </form>
              );
            }}
          </Form>
        </Card>
      </Page>
    </Can>
  );
};

export default SlotEdit;
