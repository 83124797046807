import React from 'react';
import { required, ReferenceInput, BooleanInput, useMutation, useNotify, useRedirect } from 'react-admin';
import { Form, Field } from 'react-final-form';
import { CardActions, CardContent, CardHeader, Grid, Divider } from '@material-ui/core';
import { get, merge } from 'lodash';
// import { makeStyles } from '@material-ui/styles';
import { MACHINES } from '@/vars/resources';

import { USER, MACHINE, VENDING_MACHINE_MODELS } from '@/vars';
import { useAuth } from '@/utils/auth';
import { Button, DeleteButton } from '@/components';
import { TextInput, SelectInput, AutocompleteInput, CheckboxGroupInput } from '@/ra/input';
import HiddenReferenceInput from '@/ra/HiddenReferenceInput';

const { IPAY88, CASH_COIN } = MACHINE.PAYMENT_METHOD;
const { STATUS } = MACHINE;

const ERROR_STATUS = {
  BILL_ERROR: STATUS.BILL_ERROR,
  COIN_ERROR: STATUS.COIN_ERROR,
};

const useRemoveMachine = (record, basePath) => {
  const notify = useNotify();
  const redirect = useRedirect();
  return useMutation(
    {
      type: 'update',
      resource: MACHINES,
      payload: { id: record.id, data: { isDeleted: true } },
    },
    {
      onSuccess: () => {
        redirect('list', basePath);
        notify('Successfully removed machine.');
      },
    }
  );
};

const MachineForm = ({ save, saving, record, redirect, basePath, ...rest }) => {
  const { permissions } = useAuth();
  const isAdmin = permissions.role === USER.ROLE.ADMIN;

  const onSubmit = data => {
    const { errorStatus, status, ...restData } = data;
    const shouldRemoveError = errorStatusKey => {
      const currentError = get(status, errorStatusKey);
      // Decide to remove the error status
      return currentError && errorStatus.includes(errorStatusKey) ? false : true;
    };

    const newStatus = { ...status };
    if (shouldRemoveError(STATUS.BILL_ERROR)) delete newStatus[STATUS.BILL_ERROR];
    if (shouldRemoveError(STATUS.COIN_ERROR)) delete newStatus[STATUS.COIN_ERROR];

    save(
      {
        ...restData,
        status: newStatus,
      },
      null
    );
  };
  const [remove, { loading: removing }] = useRemoveMachine(record, basePath);

  const getMachineErrorStatus = () => {
    const { status } = record;
    const errorStatus = [];
    Object.values(ERROR_STATUS).forEach(errorKeyValue => {
      if (!!get(status, errorKeyValue)) {
        errorStatus.push(errorKeyValue);
      }
    });
    return errorStatus;
  };

  // Set default payment enabled to iPay88
  const initialValues = merge(
    {
      paymentEnabled: [IPAY88],
    },
    record,
    {
      errorStatus: getMachineErrorStatus(),
    }
  );

  console.log(initialValues);

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues} keepDirtyOnReinitialize validateOnBlur>
      {({ handleSubmit, values, form }) => {
        return (
          <form onSubmit={handleSubmit} noValidate>
            <CardHeader title="Vending machine details" />
            <CardContent>
              <Grid container spacing={3}>
                <Grid container item spacing={3} xs={8}>
                  <Grid item xs={12}>
                    <TextInput source="machineUUID" label="Machine UUID" disabled />
                  </Grid>
                  <Grid item xs={12}>
                    <SelectInput
                      source="machineModel"
                      disabled={!isAdmin}
                      choices={VENDING_MACHINE_MODELS.map(model => ({ id: model, name: model }))}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SelectInput
                      source="machineMode"
                      disabled={!isAdmin}
                      choices={Object.values(MACHINE.MODE).map(v => ({ id: v, name: v }))}
                      validate={required('Please select a mode.')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput source="machineName" label="Machine name" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput source="location" />
                  </Grid>
                  {isAdmin ? (
                    <Grid item xs={12}>
                      <ReferenceInput
                        label="Client"
                        source="client.id"
                        reference="clients"
                        filterToQuery={() => {}}
                        perPage={99999}
                      >
                        <AutocompleteInput optionText="companyName" validate={required('Please select a client.')} />
                      </ReferenceInput>
                      <HiddenReferenceInput
                        source="paymentMethods.ipay88"
                        reference="clients"
                        referenceField="paymentMethods.ipay88"
                        id={get(values, 'er.id')}
                      />
                    </Grid>
                  ) : (
                    <Field component="input" type="hidden" name="client.id" initialValue={permissions.client_id} />
                  )}
                </Grid>
                <Grid container item spacing={3} xs={4} alignItems="flex-start" alignContent="flex-start">
                  <Grid item xs={12}>
                    <CheckboxGroupInput
                      label="Error status"
                      name="errorStatus"
                      options={Object.values(ERROR_STATUS).map(error => ({
                        value: error,
                        label: error,
                        disabled: get(record, ['status', error]) ? false : true,
                      }))}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <BooleanInput source="dropSensor" />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            {values.machineMode === MACHINE.MODE.COMMERCIAL && (
              <>
                <CardHeader title="Payment options" />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <CheckboxGroupInput
                        label="Enable payment"
                        name="paymentEnabled"
                        options={[{ value: IPAY88, label: 'iPay88' }, { value: CASH_COIN, label: 'Cash Coin' }]}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </>
            )}
            <Divider />
            <CardActions>
              <Button type="submit" variant="contained" color="primary" loading={saving}>
                Save
              </Button>
              <DeleteButton onClick={remove} label="Remove" loading={removing} disabled={saving} />
            </CardActions>
          </form>
        );
      }}
    </Form>
  );
};

export default MachineForm;
