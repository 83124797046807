import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  heading: {
    marginBottom: theme.spacing(2),
  },
  subHeading: {
    marginBottom: theme.spacing(5),
  },
}));

const ComingSoon = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <Typography variant="h1" className={classes.heading}>
          Coming soon
        </Typography>
        <Typography variant="subtitle1" className={classes.subHeading}>
          The new feature is about to launch. Stay tuned.
        </Typography>
        <Button variant="contained" component={NavLink} to="/">
          Back to home
        </Button>
      </div>
    </div>
  );
};

export default ComingSoon;
