import React from 'react';
import { ReferenceInput, DateInput } from 'react-admin';
import moment from 'moment';
import { get } from 'lodash';

import useDefaultFilter from '@/utils/useDefaultFilter';
import { SelectInput, Filter } from '@/ra';

const TransactionFilter = props => {
  const parseFormDataToFilters = data => {
    let filters = {};
    const { machineId, dateStart, dateEnd } = data;

    if (machineId) {
      filters['machine.id'] = machineId;
    }

    if (dateStart || dateEnd) {
      const date = [];
      if (dateStart)
        date.push({
          operator: '>=',
          value: +moment(dateStart).startOf('day'),
          isDate: true,
        });
      if (dateEnd)
        date.push({
          operator: '<=',
          value: +moment(dateEnd).endOf('day'),
          isDate: true,
        });
      filters.date = date;
    }

    return filters;
  };

  const parseFiltersToFormData = filters => {
    let formData = {};

    if (get(filters, 'machine.id')) {
      formData['machineId'] = get(filters, 'machine.id');
    }

    get(filters, 'date', []).forEach(({ operator, value }) => {
      if (operator === '>=') formData.dateStart = moment(value).format('Y-M-D');
      else formData.dateEnd = moment(value).format('Y-M-D');
    });

    return formData;
  };

  const machineInputFilter = {
    ...useDefaultFilter('client.id'),
    isDeleted: false,
  };

  return (
    <Filter parseFiltersToFormData={parseFiltersToFormData} parseFormDataToFilters={parseFormDataToFilters} {...props}>
      <ReferenceInput
        label="Machine"
        source="machineId"
        reference="machines"
        filter={machineInputFilter}
        filterToQuery={() => {}}
        sort={{ field: 'machineUUID' }}
        perPage={99999}
        allowEmpty
      >
        <SelectInput optionText="machineUUID" emptyText="Show all machines" margin="normal" />
      </ReferenceInput>
      <DateInput source="dateStart" variant="outlined" fullWidth margin="normal" />
      <DateInput source="dateEnd" variant="outlined" fullWidth margin="normal" />
    </Filter>
  );
};

TransactionFilter.defaultProps = {
  searchKeys: ['payment.transactionId', 'machine.machineUUID', 'product.name', 'amount'],
};

export default TransactionFilter;
