import React from 'react';
import { Edit, useShowController } from 'react-admin';
import { makeStyles } from '@material-ui/styles';
import { colors, Tabs, Tab, Divider } from '@material-ui/core';

import { Can, ACCESS } from '@/utils/auth';
import { Page } from '@/components';
import MachineInfo from './MachineInfo';
import MachineForm from './MachineForm';
import SlotList from './SlotList';
import SlotGrid from './SlotGrid';
import { useGetSlotsRealtime } from '../hooks';

const useStyles = makeStyles(theme => ({
  tabs: {
    marginTop: theme.spacing(3),
  },
  divider: {
    backgroundColor: colors.grey[300],
  },
  content: {
    marginTop: theme.spacing(3),
  },
}));

const MachineEdit = props => {
  const { id } = props;
  const classes = useStyles();
  const showController = useShowController(props);
  const { record } = showController;
  const [tab, setTab] = React.useState('slots');
  const handleTabsChange = (event, value) => setTab(value);
  const getSlotsState = useGetSlotsRealtime(id);

  return (
    <Can module={ACCESS.VENDING_MACHINE}>
      <Page sectionTitle="Vending Machine" title="Edit machine">
        <MachineInfo showController={showController} {...props} />
        <Tabs value={tab} onChange={handleTabsChange} className={classes.tabs}>
          <Tab label="Slots" value="slots" />
          <Tab label="Products" value="products" />
          <Tab label="Settings" value="settings" />
        </Tabs>
        <Divider className={classes.divider} />
        <div className={classes.content}>
          {tab === 'slots' && <SlotGrid machineId={id} state={getSlotsState} />}
          {tab === 'products' && <SlotList machineId={id} state={getSlotsState} machineRecord={record} />}
          {tab === 'settings' && (
            <Edit {...props} undoable={false}>
              <MachineForm />
            </Edit>
          )}
        </div>
      </Page>
    </Can>
  );
};

export default MachineEdit;
