import React, { useState } from 'react';
import { TextField, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useForm from 'react-hook-form';
import { useAuthProvider } from 'react-admin';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import routes from '@/vars/routes';
import AuthLayout from '@/layouts/AuthLayout';
import Button from '@/components/Button';

const SignIn = ({ location }) => {
  const classes = useStyles();
  const { handleSubmit, register, errors, setError } = useForm();
  const { login } = useAuthProvider();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const onSubmit = async values => {
    try {
      setLoading(true);
      await login({ username: values.email, ...values });
      history.push('/');
    } catch (error) {
      setLoading(false);
      const { code, message } = error;
      switch (code) {
        case 'auth/invalid-email':
        case 'auth/user-disabled':
        case 'auth/user-not-found':
          return setError('email', code, message);
        case 'auth/wrong-password':
          return setError('password', code, message);
        default:
          setError('email', code, message);
          throw error;
      }
    }
  };

  const params = new URLSearchParams(location.search);
  const subHeading =
    params.get('src') === 'resetPassword'
      ? 'Please sign in again with your new password.'
      : 'Please sign in to your account.';
  const defaultEmail = params.get('e');

  return (
    <AuthLayout heading="Welcome Back!" subHeading={subHeading}>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate method="POST">
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          placeholder="Email Address"
          id="email"
          name="email"
          type="email"
          autoComplete="email"
          autoFocus
          inputRef={register({
            required: 'Please enter a valid email.',
          })}
          error={Boolean(errors.email)}
          helperText={errors.email && errors.email.message}
          defaultValue={defaultEmail}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          placeholder="Password"
          id="password"
          name="password"
          type="password"
          autoComplete="current-password"
          inputRef={register({
            required: 'Please enter the password.',
          })}
          error={Boolean(errors.password)}
          helperText={errors.password && errors.password.message}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          loading={loading}
        >
          Sign In
        </Button>
        <Box display="flex" flexDirection="row-reverse">
          <Link to={routes.forgotPassword}>Forgot password?</Link>
        </Box>
      </form>
    </AuthLayout>
  );
};

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default SignIn;
