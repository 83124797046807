import React from 'react';
import { Box, colors } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { isEmpty } from 'lodash';
import { useHistory, generatePath } from 'react-router';
import routes from '@/vars/routes';

const SLOT_STATUS = {
  DEFAULT: 1,
  ERROR: 2,
  REFILL: 3,
};

const useStyles = makeStyles(theme => ({
  root: props => {
    const { status } = props;
    let backgroundColor;
    if (status === SLOT_STATUS.ERROR) backgroundColor = colors.red[50];
    if (status === SLOT_STATUS.REFILL) backgroundColor = colors.orange[50];

    return {
      position: 'relative',
      flex: 1,
      border: `1px solid ${theme.palette.divider}`,
      marginRight: -1,
      padding: 5,
      fontSize: 12,
      textAlign: 'center',
      cursor: 'pointer',
      backgroundColor,
    };
  },
  text: {
    marginBottom: theme.spacing(1),
  },
  textBold: {
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
  statusIndicator: props => {
    const { status } = props;
    let backgroundColor = colors.green[500];
    if (status === SLOT_STATUS.ERROR) backgroundColor = colors.red[500];
    if (status === SLOT_STATUS.REFILL) backgroundColor = colors.orange[500];

    return {
      position: 'absolute',
      width: 5,
      height: 5,
      borderRadius: 5,
      top: 5,
      right: 5,
      display: 'block',
      backgroundColor,
    };
  },
}));

const SlotItem = ({ slotNumber, colNumber, rowNumber, data, machineId }) => {
  const { maxCapacity, totalQuantity, error, product } = data;
  const getStatus = () => {
    if (error) return SLOT_STATUS.ERROR;
    if (totalQuantity !== undefined && totalQuantity <= 0) return SLOT_STATUS.REFILL;
    return SLOT_STATUS.DEFAULT;
  };
  const classes = useStyles({ status: getStatus() });
  const history = useHistory();

  const renderProduct = () => {
    if (isEmpty(product)) return 'Add product';
    return product.name || '';
  };

  const renderQuantity = () => {
    if (totalQuantity === undefined) return '-';
    return maxCapacity === undefined ? totalQuantity : totalQuantity + '/' + maxCapacity;
  };

  return (
    <div
      className={classes.root}
      onClick={() => {
        const path = generatePath(routes.machineSlot, { machineId, slotNumber });
        history.push(path);
      }}
    >
      <span className={classes.statusIndicator} />
      <Box className={classes.text}>{colNumber}</Box>
      <Box className={classes.textBold}>{renderProduct()}</Box>
      <Box className={classes.text}>{renderQuantity()}</Box>
    </div>
  );
};

export default SlotItem;
