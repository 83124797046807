import { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';
import moment from 'moment';
import { groupBy, sumBy, get } from 'lodash';
import { TRANSACTION, MACHINE, USER } from '@/vars';
import { TRANSACTIONS, MACHINES } from '@/vars/resources';
import { checkAccess, ACCESS, useValidateClientAccess } from '@/utils/auth';

export const useTodayTransactionsReport = permissions => {
  const dataProvider = useDataProvider();
  const [data, setData] = useState({
    summary: {
      totalTransactionsCount: 0,
      totalCash: 0,
      totalCredit: 0,
    },
    groupBy: {
      machines: [],
      transactions: [],
    },
  });
  const [loading, setLoading] = useState(true);

  // Checking login user has access to MRO. We required to look up the owner user of the client
  // to check whether MRO is enable for the owner before their sub-users can access it.
  // Admin user is excepted from this check in this particular case.
  let { access: hasMRO, loaded } = useValidateClientAccess(permissions.client_id, ACCESS.MRO);
  if (loaded) {
    hasMRO = hasMRO || permissions.role === USER.ROLE.ADMIN;
  }

  const hasAccessSalesReport = checkAccess(permissions, ACCESS.REPORT);
  const hasAccessMROReport = !!hasMRO && checkAccess(permissions, ACCESS.MRO_REPORT);
  const mode = [];
  if (hasAccessSalesReport) mode.push(MACHINE.MODE.COMMERCIAL);
  if (hasAccessMROReport) mode.push(MACHINE.MODE.MRO);

  useEffect(() => {
    if (!loaded || (!hasAccessSalesReport && !hasAccessMROReport)) return;

    const filter = {
      date: [
        {
          operator: '>=',
          value: moment()
            .startOf('day')
            .toDate(),
        },
        {
          operator: '<',
          value: moment()
            .startOf('day')
            .add(1, 'days')
            .toDate(),
        },
      ],
      client: permissions.client_id || undefined,
      status: TRANSACTION.STATUS.SUCCESS,
      mode: {
        operator: 'in',
        value: mode,
      },
    };

    dataProvider
      .getList(TRANSACTIONS, { filter })
      .then(({ data: transactions }) => {
        const filterModeCommercial = t => t.mode === MACHINE.MODE.COMMERCIAL;
        const filterModeMRO = t => t.mode === MACHINE.MODE.MRO;

        let machines = groupBy(transactions, 'machine.machineUUID');
        machines = Object.entries(machines).map(([machineUUID, machineTransactions]) => {
          const totalTransactionsCount = machineTransactions.length || 0;
          const totalCash = sumBy(machineTransactions.filter(filterModeCommercial), 'amount') || 0;
          const totalCredit = sumBy(machineTransactions.filter(filterModeMRO), 'amount') || 0;
          return {
            id: get(machineTransactions[0], 'machine.id'),
            machineUUID,
            totalTransactionsCount,
            totalCash,
            totalCredit,
            transactions: machineTransactions,
          };
        });

        setData({
          groupBy: {
            transactions,
            machines,
          },
          summary: {
            totalTransactionsCount: transactions.length || 0,
            totalCash: (sumBy(transactions.filter(filterModeCommercial), 'amount') || 0).toFixed(2),
            totalCredit: (sumBy(transactions.filter(filterModeMRO), 'amount') || 0).toFixed(2),
          },
        });
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  }, [loaded, hasAccessSalesReport, hasAccessMROReport]); // eslint-disable-line

  return {
    data,
    loading,
    hasAccessSalesReport,
    hasAccessMROReport,
  };
};

export const useMachinesReport = permissions => {
  const hasAccess = checkAccess(permissions, ACCESS.VENDING_MACHINE);
  const dataProvider = useDataProvider();
  const [data, setData] = useState({
    errors: [],
    outOfStocks: [],
    onlines: [],
    offlines: [],
  });
  const [loading, setLoading] = useState(true);

  const filter = {
    'client.id': permissions.client_id || undefined,
    isDeleted: false,
  };

  useEffect(() => {
    if (!hasAccess) return;

    dataProvider
      .getList(MACHINES, { filter })
      .then(({ data }) => {
        const errors = [];
        const outOfStocks = [];
        const onlines = [];
        const offlines = [];

        data.forEach(machine => {
          const { status, machineUUID } = machine;
          if (get(status, MACHINE.STATUS.ERROR)) {
            errors.push(machineUUID);
          }
          if (get(status, MACHINE.STATUS.OUT_OF_STOCK)) {
            outOfStocks.push(machineUUID);
          }
          if (get(status, MACHINE.STATUS.ONLINE)) {
            onlines.push(machineUUID);
          }
          if (get(status, MACHINE.STATUS.OFFLINE)) {
            offlines.push(machineUUID);
          }
        });

        setData({
          errors,
          outOfStocks,
          onlines,
          offlines,
        });
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  }, [hasAccess]); // eslint-disable-line

  return {
    data,
    loading,
    hasAccess,
  };
};
