import React, { useState } from 'react';
import { List, Datagrid, TextField, NumberField, ReferenceField, DateField, FunctionField } from 'react-admin';
import { get } from 'lodash';
import { Button, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import useDefaultFilter from '@/utils/useDefaultFilter';
import { Can, ACCESS, useAuth } from '@/utils/auth';
import { USER, TRANSACTION, MACHINE } from '@/vars';
import { Page } from '@/components';
import TransactionFilter from './TransactionFilter';

const TransactionList = props => {
  const { permissions } = useAuth();
  const isAdmin = permissions.role === USER.ROLE.ADMIN;
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const classes = useStyles();

  const filter = {
    ...useDefaultFilter('client'),
    status: TRANSACTION.STATUS.SUCCESS,
    mode: MACHINE.MODE.COMMERCIAL,
  };

  const listProps = {
    ...props,
    filter,
    sort: { field: 'date', order: 'DESC' },
    bulkActionButtons: false,
    perPage: 25,
    filters: <TransactionFilter />,
  };

  const renderGateWayField = record => {
    if (get(record, 'payment.gatewayID')) {
      return TRANSACTION.GATEWAY[`${get(record, 'payment.gatewayID')}`];
    } else if (get(record, 'payment.cash')) {
      return 'cash';
    } else {
      return 'others';
    }
  };

  const renderMoreButtonField = record => {
    return (
      <Button
        size="small"
        onClick={() => {
          setModalOpen(true);
          setModalContent(JSON.stringify(record, undefined, 2));
        }}
      >
        More
      </Button>
    );
  };

  return (
    <Can module={ACCESS.REPORT}>
      <Page sectionTitle="Sales Report" title="List transactions">
        <List {...listProps}>
          <Datagrid>
            <DateField label="Date/Time" source="date" showTime />
            <TextField label="Transaction ID" source="payment.transactionId" />
            <FunctionField label="Gateway" render={renderGateWayField} />
            {isAdmin && (
              <ReferenceField label="Company" source="client" reference="clients" link={false}>
                <TextField source="companyName" />
              </ReferenceField>
            )}
            <TextField label="Machine UUID" source="machine.machineUUID" />
            <TextField label="Product name" source="product.name" />
            <TextField label="QTY" source="quantity" />
            <NumberField
              source="amount"
              label="Price (RM)"
              locales="en-MY"
              options={{ style: 'currency', currency: 'MYR' }}
            />
            <FunctionField label="" render={renderMoreButtonField} />
          </Datagrid>
        </List>
        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
          <div className={classes.paper}>
            <pre>{modalContent}</pre>
          </div>
        </Modal>
      </Page>
    </Can>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 500,
    maxHeight: '90%',
    overflow: 'scroll',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default TransactionList;
