import React from 'react';
import { Grid, Box, Typography, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ImageField, required } from 'react-admin';
import { Field } from 'react-final-form';
import { get, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import { price } from '@/utils/validate';
import { ClientHas, ACCESS } from '@/utils/auth';
import { PRODUCTS } from '@/vars/resources';
import { MACHINE } from '@/vars';
import { NumberInput } from '@/ra/input';
import { MROAccessGroupInput } from '@/components';

const useStyles = makeStyles(theme => {
  return {
    root: {
      marginTop: theme.spacing(3),
    },
    label: {
      marginRight: theme.spacing(0.5),
    },
  };
});

const ProductForm = ({ productId, clientId, formValues, dirtyFields, modified, machineRecord }) => {
  const classes = useStyles();
  const productRecord = useSelector(state => get(state.admin, `resources.${PRODUCTS}.data.${productId}`));
  if (!productId || isEmpty(productRecord)) return null;

  const machineMode = get(machineRecord, 'machineMode', MACHINE.MODE.COMMERCIAL);

  const InlineField = ({ label, source }) => {
    return (
      <Box mb={1} display="flex">
        <Box className={classes.label}>
          <Typography>{label}:</Typography>
        </Box>
        <Box>{typeof source === 'function' ? source() : <Typography>{productRecord[source]}</Typography>}</Box>
      </Box>
    );
  };

  const initialPriceCash = get(dirtyFields, 'product.id')
    ? get(productRecord, 'priceCash')
    : get(formValues, 'product.priceCash', productRecord.priceCash);

  const initialPriceCredit = get(modified, 'product.id')
    ? get(productRecord, 'priceCredit')
    : get(formValues, 'product.priceCredit', productRecord.priceCredit);

  const initialMROAccessGroup = get(modified, 'product.id')
    ? get(productRecord, 'mroAccessGroup', null)
    : get(formValues, 'product.mroAccessGroup', productRecord.mroAccessGroup || null);

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item xs={12} md={4}>
        <ImageField record={productRecord} source="image.src" />
      </Grid>
      <Grid item container spacing={3} xs={12} md={8}>
        <Grid item xs={12}>
          <InlineField label="Product name" source="name" />
          <InlineField label="Description" source="description" />
          <InlineField label="SKU" source="sku" />
          <InlineField label="Serial number" source="serialNumber" />
          <InlineField
            label="Price (RM)"
            source={() => (
              <Typography>
                {get(productRecord, 'priceCash', 0).toLocaleString('en-MY', {
                  style: 'currency',
                  currency: 'MYR',
                })}
              </Typography>
            )}
          />
          {machineMode === MACHINE.MODE.MRO && <InlineField label="Price (Credit)" source="priceCredit" />}
        </Grid>
        {machineMode === MACHINE.MODE.MRO ? (
          <>
            <Grid item xs={12}>
              <NumberInput
                label="Price (Credit)"
                source="product.priceCredit"
                validate={[
                  required('Please enter a credit price.'),
                  price('Please enter a valid credit price with no more than two decimals.'),
                ]}
                defaultValue={initialPriceCredit}
              />
            </Grid>
            <Grid item xs={12}>
              <MROAccessGroupInput
                name="product.mroAccessGroup"
                client={clientId}
                initialValue={initialMROAccessGroup}
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <NumberInput
              label="Price (RM)"
              source="product.priceCash"
              validate={[
                required('Please enter a price.'),
                price('Please enter a valid price with no more than two decimals.'),
              ]}
              InputProps={{
                startAdornment: <InputAdornment position="start">RM</InputAdornment>,
              }}
              defaultValue={initialPriceCash}
            />
          </Grid>
        )}
        <Field component="input" type="hidden" name="product.name" initialValue={productRecord.name} />
        <Field component="input" type="hidden" name="product.description" initialValue={productRecord.description} />
        <Field component="input" type="hidden" name="product.categoryId" initialValue={productRecord.category} />
        <Field
          component="input"
          type="hidden"
          name="product.image.src"
          initialValue={get(productRecord, 'image.src')}
        />
      </Grid>
    </Grid>
  );
};

export default ProductForm;
