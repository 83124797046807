import React from 'react';
import { List, Datagrid, TextField, NumberField, ReferenceField } from 'react-admin';

import useDefaultFilter from '@/utils/useDefaultFilter';
import { Can, ACCESS, useAuth } from '@/utils/auth';
import { USER, TRANSACTION, MACHINE } from '@/vars';
import { MRO_REPORT_EMPLOYEES } from '@/vars/resources';
import routes from '@/vars/routes';
import { Page } from '@/components';
import TransactionFilter from '../TransactionFilter';

const MROTransactionByEmployeeList = ({ staticContext, ...props }) => {
  const { permissions } = useAuth();
  const isAdmin = permissions.role === USER.ROLE.ADMIN;

  const filter = {
    ...useDefaultFilter('client'),
    status: TRANSACTION.STATUS.SUCCESS,
    mode: MACHINE.MODE.MRO,
  };

  const listProps = {
    ...props,
    basePath: routes.mroTransactionsByProduct,
    resource: MRO_REPORT_EMPLOYEES,
    hasCreate: false,
    hasEdit: false,
    hasShow: false,
    hasList: true,

    filter,
    sort: { field: 'date', order: 'DESC' },
    bulkActionButtons: false,
    perPage: 25,
    filters: <TransactionFilter searchKeys={null} />,
  };

  return (
    <Can module={ACCESS.MRO_REPORT}>
      <Page sectionTitle="MRO Report" title="List by employee">
        <List {...listProps}>
          <Datagrid>
            <TextField label="Employee name" source="employee.fullName" />
            {isAdmin && (
              <ReferenceField label="Company" source="client" reference="clients" link={false}>
                <TextField source="companyName" />
              </ReferenceField>
            )}
            <TextField label="Total transactions" source="totalTransactions" />
            <NumberField label="Total Spending (Credit)" source="totalCredit" />
          </Datagrid>
        </List>
      </Page>
    </Can>
  );
};

export default MROTransactionByEmployeeList;
