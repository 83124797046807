import React from 'react';
import { Box, Typography, Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { SLOT } from '@/vars';
import SlotItem from './SlotItem';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    width: '100%',
    marginBottom: theme.spacing(3),
  },
  rowLabel: {
    marginBottom: theme.spacing(2),
  },
}));

const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

const SlotGrid = ({ state, machineId }) => {
  const classes = useStyles();
  const { data, loaded } = state;
  const COL_NUM = SLOT.GRID_COL_NUM;
  const ROW_NUM = SLOT.GRID_ROW_NUM;

  if (!loaded) return null;

  const Row = ({ rowIndex }) => {
    const rowNumber = ALPHABET[rowIndex];
    return (
      <div>
        <Typography className={classes.rowLabel}>Row {rowNumber}</Typography>
        <Box className={classes.row}>
          {[...Array(COL_NUM)].map((v, index) => {
            const slotIndex = rowIndex * COL_NUM + index;
            const slotNumber = slotIndex + 1;
            const itemData = data[slotNumber] || {};
            if (index >= 10) {
              // For some reason, we want to hide column 11 and 12 and display only 1-10
              return null;
            } else {
              return (
                <SlotItem
                  key={slotIndex}
                  data={itemData}
                  rowNumber={rowNumber}
                  colNumber={index + 1}
                  slotNumber={slotNumber}
                  machineId={machineId}
                />
              );
            }
          })}
        </Box>
      </div>
    );
  };

  return (
    <Card>
      <CardContent>
        {[...Array(ROW_NUM)].map((v, i) => (
          <Row rowIndex={i} key={i} />
        ))}
      </CardContent>
    </Card>
  );
};

export default SlotGrid;
