import React from 'react';
import clsx from 'clsx';
import { Box, AppBar, Toolbar, Button, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MenuOutlined as MenuIcon, Input as InputIcon } from '@material-ui/icons';
import { useLogout, LoadingIndicator } from 'react-admin';

import logo from '@/assets/img/logo_vecloud.png';

function Topbar(props) {
  const { className, toggleSide } = props;
  const classes = useStyles();
  const doLogout = useLogout();
  const handleLogout = () => doLogout();

  return (
    <AppBar color="default" className={clsx(classes.root, className)}>
      <Toolbar>
        {/* Responsive Menu Toggle */}
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={toggleSide}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>

        {/* Title */}
        <Box className={classes.title}>
          <img src={logo} alt="VE Cloud" className={classes.logo} draggable="false" />
        </Box>

        <Typography color="textSecondary" className={classes.beta}>
          Beta version
        </Typography>

        {/* Loading indicator */}
        <LoadingIndicator />

        {/* Logout button */}
        <Button className={classes.logoutButton} color="inherit" onClick={handleLogout}>
          <InputIcon className={classes.logoutIcon} />
          Sign out
        </Button>
      </Toolbar>
    </AppBar>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.common.white,
    boxShadow: '0 4px 8px -3px rgba(190, 190, 190, 0.5)',
  },
  title: {
    // flexGrow: 1,
    display: 'flex',
    alignSelf: 'center',
  },
  beta: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
  },
  logo: {
    width: 150,
    [theme.breakpoints.down('sm')]: {
      width: 120,
    },
    [theme.breakpoints.down('xs')]: {
      width: 90,
    },
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(1.5),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
}));

export default Topbar;
